import { Button } from "./Buttons";
import { Link } from "react-router-dom"
export function Card({title="Title", description="Description", backzeit="keine Angabe", temperatur="keine Angabe",link="#", kategorie, image="https://picsum.photos/400/200"}) {
    
    let encodedLink = "rezept/"+encodeURIComponent(link);

    return (
        <div className=" bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow dark:bg-gray-800 dark:border-gray-700">
            <Link to={encodedLink}>
                <img className="rounded-t-lg w-full object-contain" src={image} alt="" />
            </Link>
            
            <div className="p-5">
                <small>{kategorie}</small>
                <Link to={encodedLink}>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-teal-500 hover:text-teal-600 cursor-pointer dark:text-white">{title}</h5>
                </Link>

                <div className="inline-flex text-sm items-center mr-3 text-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <span className="ml-1 text-gray-800">{backzeit}</span>
                </div>

                <div className="inline-flex text-sm items-center text-orange-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z" />
                </svg>
                <span className="ml-1 text-gray-800">{temperatur}</span>
                </div>



                <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700"></hr>

                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400 ">{description}</p>
                {/* <Button showIcon="true" >Ansehen</Button> */}
            </div>
        </div>
    )
}