import { kategorien, getRezepte } from "../data/rezepte";

// export async function loader() {
    const rezepte = getRezepte();
//     return { rezepte };
// }

export function CategoryList({selectedCategories = null, onSelectCategories}) {
    return (
        <div>
            <h4 className="font-bold mb-4">Kategorien</h4>
            <div className="text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                {kategorien.map(kategorie => {
                    // Count the number of recipes in each category
                    const count = rezepte.reduce((acc, recipe) => {
                        return recipe.kategorie === kategorie ? acc + 1 : acc;
                    }, 0);

                    return (
                        <a 
                            href="#" 
                            className="block w-full px-4 py-2 border-b last:border-none border-gray-200 cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:outline-none  dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                            key={kategorie}
                            onClick={(e) => {
                                onSelectCategories(selectedCategories.includes(kategorie) ? selectedCategories.filter(cat => cat !== kategorie) : [...selectedCategories, kategorie])
                            }}
                        >
                            <div className={'inline-flex items-center '+(selectedCategories.some(cat => cat===kategorie) ? 'font-bold' : '')}>
                            {selectedCategories.includes(kategorie) ? <span className="flex w-3 h-3 me-3 bg-teal-500 rounded-full"></span>:''} 
                            {kategorie} ({count})
 </div>
                        </a>
                    );
                })}
                
            </div>
            {
                selectedCategories && (
                    <div className="mx-auto text-center pt-2">
                    <button type="submit" onClick={(e) => {e.preventDefault(); onSelectCategories([]);}} className="mx-auto text-center text-teal-500 hover:text-teal-600 text-sm uppercase font-bold">Filter Zurücksetzen</button>
                    </div>
                )
            }

        </div>
    );   
}
