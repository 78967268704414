import {  useLoaderData } from "react-router-dom";
import { getRezeptByName } from "../data/rezepte";
import ErrorPage from "../error-page";


export async function recipeDetailLoader(params) {
  const rezept = getRezeptByName(params.params.name);
  return rezept;
}

export function RecipeDetail() {
    const { rezept } = useLoaderData();
    
    if (rezept) {

      return(
        <div>
          {rezept.name}
        </div>
      )
    }
    else {
      return (
        <ErrorPage></ErrorPage>
      )
    }
}