import { Header } from "./components/Header";
import { RecipeGrid } from "./routes/RecipesGrid";
import { Outlet } from "react-router-dom"
export function App() {
    return (
      <main className="mx-auto p-4">
        <Header>AppliCake</Header>
        {/* <RecipeGrid></RecipeGrid> */}
        <Outlet/>
      </main>
    );
  }