// kuchenrezepte.js

const kategorien = ["Klassiker", "Obstkuchen", "Spezialitäten", "Schnelle Kuchen", "Vegane Kuchen", "Rührkuchen"];

const rezepte = [
    {
        kategorie: "Klassiker",
        name: "Klassischer Schokoladenkuchen",
        image: "https://picsum.photos/400/200",
        zutaten: ["200g Zartbitterschokolade", "150g Butter", "100g Zucker", "4 Eier", "150g Mehl", "1 TL Backpulver"],
        backzeit: "45 Minuten",
        temperatur: "180°C",
        anleitung: "Schokolade und Butter schmelzen, mit Zucker und Eiern verrühren. Mehl und Backpulver unterheben. Bei 180°C für 45 Minuten backen."
    },
    {
        kategorie: "Klassiker",
        name: "Klassischer Pflaumenkuchen",
        image: "https://picsum.photos/400/200",
        zutaten: ["200g Zartbitterschokolade", "150g Butter", "100g Zucker", "4 Eier", "150g Mehl", "1 TL Backpulver"],
        backzeit: "45 Minuten",
        temperatur: "180°C",
        anleitung: "Schokolade und Butter schmelzen, mit Zucker und Eiern verrühren. Mehl und Backpulver unterheben. Bei 180°C für 45 Minuten backen."
    },
    {
        kategorie: "Obstkuchen",
        name: "Einfacher Apfelkuchen",
        image: "https://picsum.photos/400/200",
        zutaten: ["4 Äpfel", "250g Mehl", "125g Zucker", "125g Butter", "1 Ei", "1 TL Backpulver", "1 TL Zimt"],
        backzeit: "60 Minuten",
        temperatur: "175°C",
        anleitung: "Äpfel schälen und in Scheiben schneiden. Mehl, Zucker, Butter, Ei und Backpulver zu einem Teig verarbeiten. Äpfel darauflegen, mit Zimt bestreuen. Bei 175°C für 60 Minuten backen."
    },
    {
        kategorie: "Spezialitäten",
        name: "Schwarzwälder Kirschtorte",
        image: "https://picsum.photos/400/200",
        zutaten: ["6 Eier", "200g Zucker", "1 Päckchen Vanillezucker", "100g Mehl", "100g Speisestärke", "50g Kakaopulver", "1 TL Backpulver", "500g Kirschen", "500ml Sahne", "3 EL Kirschwasser"],
        backzeit: "30 Minuten",
        temperatur: "180°C",
        anleitung: "Eier und Zucker schaumig schlagen. Trockene Zutaten mischen und unterheben. Bei 180°C für 30 Minuten backen. Abkühlen lassen, mit Kirschen, Sahne und Kirschwasser füllen."
    },
    {
        kategorie: "Schnelle Kuchen",
        name: "Blitzkuchen",
        image: "https://picsum.photos/400/200",
        zutaten: ["200g Mehl", "150g Zucker", "1 Päckchen Vanillezucker", "3 Eier", "100ml Öl", "100ml Milch", "1 TL Backpulver"],
        backzeit: "35 Minuten",
        temperatur: "180°C",
        anleitung: "Alle Zutaten zu einem glatten Teig verrühren. In eine Form geben und bei 180°C für 35 Minuten backen."
    },
    {
        kategorie: "Vegane Kuchen",
        name: "Veganer Bananenkuchen",
        image: "https://picsum.photos/400/200",
        zutaten: ["3 reife Bananen", "200g Mehl", "100g Zucker", "80ml Pflanzenöl", "1 TL Backpulver", "1 TL Zimt"],
        backzeit: "50 Minuten",
        temperatur: "180°C",
        anleitung: "Bananen zerdrücken und mit den restlichen Zutaten zu einem Teig verrühren. Bei 180°C für 50 Minuten backen."
    }
    // Weitere Rezepte...
];

export { kategorien };


export function getRezepte() {
    return rezepte
}

export function getRezeptByName(name) {
    let rezept = rezepte.find((rezept) => (rezept.name.toLowerCase() === decodeURIComponent(name.toLowerCase())))
    return { rezept: rezept };
}