import { Link } from "react-router-dom";
import logo  from "../img/favicon.png"
export function Header({children}) {
    return (

        <div className="mx-auto">

        <header className="mb-4">
            <Link to="/">
            <div className="p-4 mb-4 flex w-full items-center gap-2 justify-center shadow-sm transition-shadow hover:shadow-md bg-white rounded-lg">
                <img className="block h-12" src={logo}></img>
            <h1 className="text-2xl  subpixel-antialiased tracking-wide text-center font-semibold text-teal-500 hover:text-teal-600">{children}</h1>
            </div>
            </Link>
        </header>
        
        </div>
        
    );
}