import * as React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import ErrorPage from "./error-page";
import "./style.scss";
import { RecipeGrid } from "./routes/RecipesGrid";
import { RecipeDetail, recipeDetailLoader } from "./routes/RecipeDetail";
import { recipeGridLoader  } from "./routes/RecipesGrid";

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(new URL('./service-worker.js', import.meta.url)).then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, err => {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}


const router = createBrowserRouter([
    {
      path: "",
      element: <App />,
      errorElement: <ErrorPage/>,      
      children: [
        {
          loader: recipeGridLoader,
          index: true,
          element: <RecipeGrid/>
        },
        {
          path: "rezept/:name",
          element: <RecipeDetail/>,
          loader: recipeDetailLoader
        }
      ]
    },
    {
        path: "*", // Wildcard route for unmatched paths
        element: <Navigate to="/" replace />
    }
  ]);

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
    <React.StrictMode>
        {/* <App /> */}
        <RouterProvider router={router} />
    </React.StrictMode>
);