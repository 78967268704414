import { getRezepte } from "../data/rezepte";
import { Card } from "../components/Card";
import { Search } from "../components/Search";
import { CategoryList } from "../components/CategoryList";
import { useLoaderData } from "react-router-dom";
import { useState } from 'react';
import {matchSorter} from 'match-sorter'



export async function recipeGridLoader() {
    const rezepte = await getRezepte();
    return { rezepte };
}

export function RecipeGrid() {

    const { rezepte } = useLoaderData();

    const [selectedCategories, setSelectedCategories] = useState([]);

    const [searchQuery, setSearchQuery] = useState('');    

    return (
        <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
            <div className=" bg-white p-4 rounded-lg shadow-sm  hover:shadow-md transition-shadow sticky top-4">
                <Search handleSearchQuery={setSearchQuery}></Search>
                <CategoryList selectedCategories={selectedCategories} onSelectCategories={setSelectedCategories}></CategoryList>
                </div>
            </div>
        <div className="col-span-12 md:col-span-8 ">
        <div className="grid gird-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {
                
                matchSorter(rezepte.filter(rezept => selectedCategories.length == 0 ||  selectedCategories.includes(rezept.kategorie)), searchQuery, {keys: ['name', 'kategorie', 'zutaten', 'backzeit', 'temperatur', 'anleitung']})
                .map(rezept => (
                    <Card key={rezept.name} title={rezept.name} description={rezept.anleitung} backzeit={rezept.backzeit} temperatur={rezept.temperatur} kategorie={rezept.kategorie} image={rezept.image} link={rezept.name}></Card>
                ))
            }

        </div>
        </div>
      </div>

    )
}